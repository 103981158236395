<template>
    <div class="card card-f-h flex flex-col">
        <ConfirmationModal codeConfirmation @success="getAll" />

        <div class="flex items-end justify-between border-b border-gray-400 mb-3 pb-3">
            <router-link :to="{ name: 'employees_all' }">
                <el-button type="primary">
                    {{ $t('employees.go_back') }}
                </el-button>
            </router-link>
            <el-input v-model="quickSearch" suffix-icon="el-icon-search" class="w-72" :placeholder="$t('common.search')" clearable />
        </div>

        <EmployeesArchivedTable
            :employeesData="employeesData"
            @handleRestore="handleRestore"
            @handleDelete="handleDelete"
        />

        <InitialLoader v-if="$wait.is('loading.initial_*')" />
    </div>
</template>
<script>
export default {
    components: {
        EmployeesArchivedTable: () => import(/* webpackChunkName: "EmployeesArchivedTable" */ './components/EmployeesArchivedTable'),
    },

    data() {
        return {
            employeesData:       [],
            employeesDataOrigin: [],
            quickSearch:         '',
        };
    },

    watch: {
        quickSearch() {
            this.filter();
        },
    },

    beforeCreate() {
        this.$wait.start('loading.initial_employees_archive');
    },

    created() {
        this.$store.commit('setPageTitle', this.$t('employees.all_employees_archive'));
        this.$store.commit('setActiveMenuItem', '3-1');

        this.getAll();
    },

    methods: {
        async getAll() {
            this.quickSearch = '';
            this.$wait.start('loading');

            const employees = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/employees/employees/archived`);
            this.employeesData = Object.freeze(employees.data);
            this.employeesDataOrigin = Object.freeze(employees.data);

            this.$wait.end('loading');
            this.$wait.end('loading.initial_employees_archive');
        },

        async handleRestore(uuid) {
            this.$wait.start('loading');
            try {
                await axios.patch(`${process.env.VUE_APP_CORE_API_URL}/employees/employees/${uuid}/restore`);
                this.$notify.success({ title: this.$t('common.success') });
                this.$wait.end('loading');
                this.getAll();
            } catch (error) {
                this.$wait.end('loading');
            }
        },

        handleDelete(uuid) {
            this.$store.commit('showConfirmationModal', {
                actionUrl:  `${process.env.VUE_APP_CORE_API_URL}/employees/employees/${uuid}/destroy`,
                actionType: 'destroy',
            });
        },

        filter() {
            const search = this.quickSearch.toLowerCase();
            this.employeesData = this.employeesDataOrigin.filter(client => (
                client.name.toLowerCase().includes(search)
                || (client.surname && client.surname.toLowerCase().includes(search))
            ));
        },
    },
};
</script>
